import Swiper from "swiper";
import { Navigation } from 'swiper/modules';

const realizationSections = document.querySelectorAll('.section-news__wrapper');


Swiper.use([Navigation]);
let newsArray = [];

const initSwiper = () => {
    realizationSections.forEach((section, i) => {
        let init = false;
        const arrowDiv = section.parentElement.parentElement.querySelector('.section-news__arrow');
        if(arrowDiv) {
            section.parentElement.parentElement.querySelector(`.section-news__arrow--next`).classList.add(`section-news__arrow--next-${i}`);
            section.parentElement.parentElement.querySelector(`.section-news__arrow--prev`).classList.add(`section-news__arrow--prev-${i}`);
        }
        if (section.querySelector('.swiper-wrapper')) {
            if (section.classList.contains('section-news__wrapper--swiper')) {
                section.classList.add(`section-news__wrapper--swiper-${i}`);
                const countSpan = section.parentElement.parentElement.querySelector(".section-news__current-slide");
                const realizationsCarousel = new Swiper(`.section-news__wrapper--swiper-${i}`, {
                    slidesPerView: 'auto',
                    slideActiveClass: 'is-active',
                    breakpoints: {
                        0 : {
                            slidesPerView: 1.25,
                            spaceBetween: 16,
                        }, 
                        992: {
                            slidesPerView: 3,
                            spaceBetween: 32,
                        }
                    },
                    navigation: {
                        nextEl: `.section-news__arrow--next-${i}`,
                        prevEl: `.section-news__arrow--prev-${i}`,
                    },
                });

                if (countSpan) {
                    realizationsCarousel.on("slideChange", (e) => {
                        const {activeIndex} = e;
                        countSpan.textContent = activeIndex + 1;
                    })
                }
            } else if(section.classList.contains('section-news__wrapper--swiper--mobile')) {
                section.classList.add(`section-news__wrapper--swiper--mobile-${i}`);
                if (!init && window.innerWidth < 991.98) {
                    init = true;
                    const countSpan = section.parentElement.parentElement.querySelector(".section-news__current-slide");
                    const realizationsCarousel = new Swiper(`.section-news__wrapper--swiper--mobile-${i}`, {
                        slidesPerView: 'auto',
                        slideActiveClass: 'is-active',
                        slidesPerView: 1.25,
                        spaceBetween: 16,
                        navigation: {
                            nextEl: `.section-news__arrow--next-${i}`,
                            prevEl: `.section-news__arrow--prev-${i}`,
                        },
                    });

                    if (countSpan) {
                        realizationsCarousel.on("slideChange", (e) => {
                            const {activeIndex} = e;
                            countSpan.textContent = activeIndex + 1;
                        })
                    }

                    newsArray.push(realizationsCarousel);
                } else if (window.innerWidth > 991.98) {
                    newsArray.forEach((item => {
                        item.destroy();
                    }))
                    init = false;
                }
            }
        }
    })
}

initSwiper();
window.addEventListener('resize', initSwiper);
